// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    :host {
      display: block;
      min-height: 100vh;
      min-width: 100vw;
      background-color: #f3f4f6;
    }

    .auth-container {
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      width: 100%;
    }
  
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImF1dGgtbGF5b3V0LmNvbXBvbmVudC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0lBQ0k7TUFDRSxjQUFjO01BQ2QsaUJBQWlCO01BQ2pCLGdCQUFnQjtNQUNoQix5QkFBeUI7SUFDM0I7O0lBRUE7TUFDRSx1QkFBdUI7TUFDdkIsbUJBQW1CO01BQ25CLGlCQUFpQjtNQUNqQixXQUFXO0lBQ2IiLCJmaWxlIjoiYXV0aC1sYXlvdXQuY29tcG9uZW50LnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgOmhvc3Qge1xuICAgICAgZGlzcGxheTogYmxvY2s7XG4gICAgICBtaW4taGVpZ2h0OiAxMDB2aDtcbiAgICAgIG1pbi13aWR0aDogMTAwdnc7XG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZjNmNGY2O1xuICAgIH1cblxuICAgIC5hdXRoLWNvbnRhaW5lciB7XG4gICAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICBtaW4taGVpZ2h0OiAxMDB2aDtcbiAgICAgIHdpZHRoOiAxMDAlO1xuICAgIH1cbiAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/layouts/auth-layout/auth-layout.component.ts"],"names":[],"mappings":";IACI;MACE,cAAc;MACd,iBAAiB;MACjB,gBAAgB;MAChB,yBAAyB;IAC3B;;IAEA;MACE,uBAAuB;MACvB,mBAAmB;MACnB,iBAAiB;MACjB,WAAW;IACb;;AAEJ,4uBAA4uB","sourcesContent":["\n    :host {\n      display: block;\n      min-height: 100vh;\n      min-width: 100vw;\n      background-color: #f3f4f6;\n    }\n\n    .auth-container {\n      justify-content: center;\n      align-items: center;\n      min-height: 100vh;\n      width: 100%;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
