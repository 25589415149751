import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { routes } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { importProvidersFrom } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { environment } from './environments/environment';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxBackButtonModule } from 'ngx-back-button';
import { MarkdownModule } from 'ngx-markdown';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorInterceptor } from './app/helpers/error.interceptor';
import { jwtInterceptor } from './app/core/interceptors/jwt.interceptor';
import { ActivityInterceptor } from './app/interceptors/activity.interceptor';
import { provideNgxMask } from 'ngx-mask';
import { provideUserIdleConfig } from 'angular-user-idle';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n';
import { enUS } from 'date-fns/locale';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthGuard } from './app/login/service/authguard';
import { UtilityService } from './app/common/utility';
import { AlertService } from './app/alerts/alert.service';
import { AccountService } from './app/login/service/account.service';
import { VendorService } from './app/vendorList/services/vendor.service';
import { AssessmentService } from './app/assessment/services/assessment.service';
import { FileAttachmentService } from './app/common/fileattachment.service';
import { ClientService } from './app/client/services/client.services';
import { StaffService } from './app/staff/services/staff.service';
import { ReportService } from './app/reports/services/report.service';
import { TooltipListPipe } from './app/common/pipes/tooltip.list.pipe';
import { NgxBackButtonService } from 'ngx-back-button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from './app/material.module';
import { NgZorroAntdModule } from './app/zorro.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PortalModule } from '@angular/cdk/portal';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MainNavigationModule } from './app/main-navigation/main-navigation.module';
import { VendorModule } from './app/vendor/vendor.module';
import {
    PageService,
    SortService,
    FilterService,
    GroupService,
    DetailRowService,
    PdfExportService,
    ExcelExportService,
    ToolbarService,
    InfiniteScrollService
} from '@syncfusion/ej2-angular-grids';
import { MaskedDateTimeService } from '@syncfusion/ej2-angular-calendars';
import {
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
} from '@syncfusion/ej2-angular-schedule';
import { registerLicense } from '@syncfusion/ej2-base';
import { ApiService } from './app/core/services/api.service';

// Import all Ant Design icons
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

export function tokenGetter() {
  return localStorage.getItem('token');
}
registerLicense('Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX5ec3VTQ2NfVkF0XUs=');

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      AngularMaterialModule,
      NgZorroAntdModule,
      FontAwesomeModule,
      PortalModule,
      MatTabsModule,
      MatExpansionModule,
      NzDropDownModule,
      NgbDropdownModule,
      MainNavigationModule,
      VendorModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAuthModule,
      AngularFireAnalyticsModule,
      AngularFirestoreModule,
      AngularFireDatabaseModule,
      AngularFireFunctionsModule,
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: ['localhost:7047', 'localhost:4200', 'localhost', 'myworkatcornerstone.com'],
          disallowedRoutes: [
            '/auth/login',
            '/auth/forgotPass',
            '/auth/ResetPassword',
            '/Token/Refresh',
          ],
        },
      }),
      NgxBackButtonModule.forRoot({ rootUrl: '/dashboard' }),
      MarkdownModule.forRoot(),
      AppRoutingModule
    ),
    provideHttpClient(withInterceptors([ErrorInterceptor, jwtInterceptor, ActivityInterceptor])),
    provideNgxMask(),
    provideUserIdleConfig({ idle: 600, timeout: 300, ping: 120 }),
    // Base Services (initialize first)
    ApiService,
    // Services
    AuthGuard,
    UtilityService,
    AlertService,
    AccountService,
    VendorService,
    AssessmentService,
    FileAttachmentService,
    ClientService,
    StaffService,
    ReportService,
    TooltipListPipe,
    PageService,
    SortService,
    FilterService,
    GroupService,
    DetailRowService,
    PdfExportService,
    ExcelExportService,
    ToolbarService,
    MaskedDateTimeService,
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    InfiniteScrollService,
    NgxBackButtonService,
    // Configuration
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    { provide: NZ_DATE_LOCALE, useValue: enUS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: REGION, useValue: 'us-central1' }
  ]
}).catch(err => console.error(err));
