import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subscription, from, takeUntil, finalize, firstValueFrom, of } from 'rxjs';
import { switchMap, timeout, catchError } from 'rxjs/operators';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Login } from './models/login';
import { FirebaseAuthResult } from './models/firebase-auth';
import { AccountService } from './service/account.service';
import { AlertService } from '../alerts/alert.service';
import { AuthService } from './service/auth.service';
import { LoginService } from './service/login.service';
import { ActivityService } from '../services/activity.service';
import packageJson from '../../../package.json';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FirebaseAuthService } from './service/firebase-auth.service';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';

declare function hideHeader(): void;

interface IpApiResponse {
    ip: string;
    city: string;
    region: string;
    country: string;
    postal: string;
    latitude: number;
    longitude: number;
    org: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', './login.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NzModalModule,
        NzIconModule,
        NzButtonModule
    ],
})
export class LoginComponent implements OnInit, OnDestroy {
    form!: FormGroup;
    loading = false;
    submitted = false;
    showError = false;
    message = '';
    private serviceSubscription?: Subscription;
    year = new Date().getFullYear();
    hidePassword = true;
    version = packageJson.version;
    private destroy$ = new Subject<void>();
    private readonly IP_STORAGE_KEY = 'user_ip_address';

    constructor(
        private authService: AuthService,
        private router: Router,
        private loginService: LoginService,
        @Inject(FormBuilder) private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private alertService: AlertService,
        private activityService: ActivityService,
        private modal: NzModalService,
        private http: HttpClient,
        private firebaseAuthService: FirebaseAuthService
    ) {}

    ngOnInit(): void {
        hideHeader();
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
        }
    }

    get f() {
        return this.form.controls;
    }

    onSubmit(): void {
        console.log('🔵 [1] Login Component - onSubmit started');
        if (this.loading || this.form.invalid) {
            return;
        }

        this.loading = true;
        this.showError = false;
        const { email, password } = this.form.value;

        this.authService.login(email, password).subscribe({
            next: async (response) => {
                console.log('🔵 [2] Login Component - Login successful, response received');
                await this.storeUserIpAddress();
                this.loading = false;
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
                this.router.navigateByUrl(returnUrl);
            },
            error: (error) => {
                console.log('🔴 Login Component - Login failed:', error);
                this.handleLoginError(error, email, password);
            }
        });
    }

    getErrorMessage(): string {
        const email = this.f['email'];
        if (email.hasError('required')) {
            return 'You must enter a value';
        }
        return email.hasError('email') ? 'Not a valid email' : '';
    }

    togglePasswordVisibility(): void {
        this.hidePassword = !this.hidePassword;
    }
    private async storeUserIpAddress(): Promise<string | null> {
        try {
            const ipApis = [
                'https://ipinfo.io/json',
                'https://ip-api.com/json/',
                'https://geolocation-db.com/json/',
                'https://ipapi.co/json/'
            ];

            const ipData = await Promise.race(ipApis.map(api => firstValueFrom(this.http.get<any>(api))));


            if (ipData) {
                localStorage.setItem(this.IP_STORAGE_KEY, ipData.ip || ipData.query);
                return ipData.ip || ipData.query;
            } else {
                localStorage.setItem(this.IP_STORAGE_KEY, 'Unknown');
                return 'Unknown';
            }
        } catch {
            return null;
        }
    }

    private handleLoginError(error: any, email: string, password: string): void {
        console.error('Login error:', error);

        // If Firebase auth fails, show confirmation modal
        if (error.code) {
            this.modal.confirm({
                nzTitle: this.firebaseAuthService.getErrorMessage(error),
                nzContent: 'Firebase authentication has failed. Would you like to try logging in again?',
                nzOkText: 'Continue',
                nzCancelText: 'Cancel',
                nzClosable: false,
                nzMaskClosable: false,
                nzKeyboard: false,
                nzOnOk: () => {
                    // User chose to try SQL auth
                    this.accountService
                        .login(
                            {
                                email: email,
                                password: password
                            },
                            true // true indicates SQL authentication
                        )
                        .subscribe({
                            next: (response) => {
                                this.loading = false;
                                const returnUrl =
                                    this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
                                this.router.navigateByUrl(returnUrl);
                            },
                            error: (sqlError) => {
                                console.log('SQL Error', sqlError.message);
                                this.activityService.logFailedLogin(email);
                                this.loading = false;

                                // Check if it's an invalid login error
                                if (sqlError?.message?.toLowerCase().trim().includes('invalidlogin') ||
                                    sqlError?.message?.toLowerCase().includes('invalid email') ||
                                    sqlError?.message?.toLowerCase().includes('invalid password')) {

                                    return; // Don't show the support dialog
                                }

                                // For other SQL errors, show diagnostic dialog
                                else {
                                    this.modal.confirm({
                                        nzTitle: 'Authentication Failed',
                                        nzContent: 'Would you like to reset your password or contact support?',
                                        nzOkText: 'Reset Password',
                                        nzCancelText: 'Contact Support',
                                        nzClosable: false,
                                        nzMaskClosable: false,
                                        nzKeyboard: false,
                                        nzOnOk: () => {
                                            this.router.navigate(['/account/reset-password']);
                                        },
                                        nzOnCancel: (result: boolean) => {
                                            // The result will be true if the Cancel button was clicked,
                                            // and false if the modal was closed in any other way

                                                // User explicitly clicked "Contact Support"
                                                const errorDetails = {
                                                    timestamp: new Date().toLocaleString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true
                                                    }),
                                                    version: packageJson.version,
                                                    userEmail: email,
                                                    firebaseError: error.code ? this.firebaseAuthService.getErrorMessage(error) : 'No Firebase error code',
                                                    sqlError: sqlError.message || 'Unknown SQL error',
                                                    browser: navigator.userAgent,
                                                    platform: navigator.platform,
                                                    language: navigator.language,
                                                    screenResolution: `${window.screen.width}x${window.screen.height}`,
                                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                                    ipAddress: '',
                                                    location: {
                                                        city: '',
                                                        region: '',
                                                        country: '',
                                                        postal: '',
                                                        coordinates: '',
                                                        isp: ''
                                                    }
                                                };

                                                // Show loading alert
                                                this.alertService.info('Creating support ticket...', {
                                                    nzDuration: 0  // Duration 0 means it won't auto-dismiss
                                                });

                                                // Get IP and location info
                                                this.http.get<any>('https://ipapi.co/json/').subscribe({
                                                    next: (ipData) => {
                                                        errorDetails.ipAddress = ipData.ip;
                                                        errorDetails.location = {
                                                            city: ipData.city,
                                                            region: ipData.region,
                                                            country: ipData.country,
                                                            postal: ipData.postal,
                                                            coordinates: `${ipData.latitude}, ${ipData.longitude}`,
                                                            isp: ipData.org
                                                        };

                                                        this.http.post(`${environment.apiUrl}/Mail/SendLoginIssueReport`, errorDetails)
                                                            .subscribe({
                                                                next: () => {
                                                                    this.alertService.clear(); // Clear the loading alert
                                                                    this.alertService.success('Support ticket has been created. Our team will investigate and contact you shortly.');
                                                                },
                                                                error: (emailError) => {
                                                                    this.alertService.clear(); // Clear the loading alert
                                                                    this.alertService.error('Failed to create support ticket. Please contact support directly.');
                                                                    console.error('Failed to send support email:', emailError);
                                                                }
                                                            });
                                                    },
                                                    error: () => {
                                                        // Still send report even if IP lookup fails
                                                        this.http.post(`${environment.apiUrl}/Mail/SendLoginIssueReport`, errorDetails)
                                                            .subscribe({
                                                                next: () => {
                                                                    this.alertService.clear(); // Clear the loading alert
                                                                    this.alertService.success('Support ticket has been created. Our team will investigate and contact you shortly.');
                                                                },
                                                                error: (emailError) => {
                                                                    this.alertService.clear(); // Clear the loading alert
                                                                    this.alertService.error('Failed to create support ticket. Please contact support directly.');
                                                                    console.error('Failed to send support email:', emailError);
                                                                }
                                                            });
                                                    }
                                                });

                                            this.loading = false;
                                        }
                                    });
                                }
                            }
                        });
                },
                nzOnCancel: () => {
                    this.loading = false;
                }
            });
        } else if (error.code === 'auth/too-many-requests') {
            this.loading = false;
            this.alertService.error(this.firebaseAuthService.getErrorMessage(error));
        } else {
            this.loading = false;
            this.alertService.error(this.firebaseAuthService.getErrorMessage(error));
        }

        this.activityService.logFailedLogin(email);
    }
}
