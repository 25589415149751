import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { Forgotpass } from '../models/login';
import { AccountService } from '../service/account.service';
import { AlertService } from '../../alerts/alert.service';
import { AuthService } from '../service/auth.service';
import { LoginService } from '../service/login.service';
import { FirebaseAuthService } from '../service/firebase-auth.service';
const swal = require('sweetalert');
declare function hideHeader(): any;

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css'],
    standalone: false,
})
export class ForgotPasswordComponent {
    form!: FormGroup;
    loading = false;
    submitted = false;
    showError: boolean = false;
    message: string;
    year: any = new Date().getFullYear();
    constructor(
        private authService: AuthService,
        private router: Router,
        private loginService: LoginService,
        @Inject(FormBuilder) private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private alertService: AlertService,
        private firebaseAuth: FirebaseAuthService
    ) {}
    hide = true;
    ngOnInit(): void {
        hideHeader();
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }
    get f() {
        return this.form.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.alertService.clear();

        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.showError = false;
        const email = this.f['email'].value.trim();

        this.firebaseAuth
            .sendPasswordResetEmail(email)
            .pipe(first())
            .subscribe({
                next: () => {
                    swal({
                        title: 'Check your inbox',
                        text: `We sent a password reset link to ${email}. If you don't see the email, please check your spam folder.`,
                        icon: 'success',
                    });
                    this.loading = false;
                    this.router.navigateByUrl('/account/login');
                },
                error: (error: any) => {
                    this.loading = false;
                    let errorMessage = 'Failed to send password reset email';

                    if (error.code === 'auth/user-not-found') {
                        errorMessage = 'No account found with this email address';
                    } else if (error.code === 'auth/invalid-email') {
                        errorMessage = 'Invalid email address';
                    } else if (error.code === 'auth/too-many-requests') {
                        errorMessage = 'Too many requests. Please try again later';
                    }

                    this.alertService.error(errorMessage);
                    this.showError = true;
                },
            });
    }

    getErrorMessage() {
        let email = this.f['email'];
        if (email.hasError('required')) {
            return 'You must enter a value';
        }
        return email.hasError('email') ? 'Not a valid email' : '';
    }
}
