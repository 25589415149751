import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  // Standard breakpoints following Material Design guidelines
  private readonly BREAKPOINTS = {
    MOBILE: '(max-width: 767.98px)',
    TABLET: '(min-width: 768px) and (max-width: 1366.98px)',
    DESKTOP: '(min-width: 1367px)'
  };

  private _platform: 'ios' | 'android' | 'other';
  private isMobileSubject = new BehaviorSubject<boolean>(false);
  private isTabletSubject = new BehaviorSubject<boolean>(false);
  private isDesktopSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private cdkPlatform: Platform,
    private breakpointObserver: BreakpointObserver
  ) {
    this._platform = this.detectPlatform();
    this.applyPlatformClasses();
    this.setupBreakpointObserver();
  }

  private detectPlatform(): 'ios' | 'android' | 'other' {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log('Platform:', this.cdkPlatform);
    console.log('UserAgent:', userAgent);

    // iOS detection - check for iOS specific identifiers
    if (
      userAgent.includes('iphone') ||
      userAgent.includes('ipad') ||
      userAgent.includes('ipod') ||
      (userAgent.includes('safari') && userAgent.includes('mobile')) ||
      // Check for iOS simulator
      (userAgent.includes('mac') && 'ontouchend' in document)
    ) {
      return 'ios';
    }

    // Android detection
    if (
      userAgent.includes('android') ||
      userAgent.includes('linux; u;') ||
      userAgent.includes('linux; mobile')
    ) {
      return 'android';
    }

    return 'other';
  }

  private setupBreakpointObserver(): void {
    // Observe all breakpoints simultaneously
    this.breakpointObserver
      .observe([
        this.BREAKPOINTS.MOBILE,
        this.BREAKPOINTS.TABLET,
        this.BREAKPOINTS.DESKTOP
      ])
      .pipe(distinctUntilChanged())
      .subscribe(result => {
        const isMobile = result.breakpoints[this.BREAKPOINTS.MOBILE];
        const isTablet = result.breakpoints[this.BREAKPOINTS.TABLET];
        const isDesktop = result.breakpoints[this.BREAKPOINTS.DESKTOP];

        console.log('Breakpoints matched:', { isMobile, isTablet, isDesktop });

        // Update subjects
        this.isMobileSubject.next(isMobile);
        this.isTabletSubject.next(isTablet);
        this.isDesktopSubject.next(isDesktop);

        // Update platform classes
        this.updatePlatformClasses(isMobile || (isTablet && this.isNativeMobile));
      });
  }

  private updatePlatformClasses(isMobile: boolean): void {
    const classList = document.documentElement.classList;
    if (isMobile) {
      classList.add('mobile-view');
      classList.add('is-mobile');
    } else {
      classList.remove('mobile-view');
      classList.remove('is-mobile');
    }
  }

  get platform() {
    return this._platform;
  }

  get isIOS() {
    return this._platform === 'ios';
  }

  get isAndroid() {
    return this._platform === 'android';
  }

  get isMobile$(): Observable<boolean> {
    return this.isMobileSubject.asObservable();
  }

  get isMobile(): boolean {
    return this.isMobileSubject.value || (this.isTabletSubject.value && this.isNativeMobile);
  }

  get isTablet$(): Observable<boolean> {
    return this.isTabletSubject.asObservable();
  }

  get isTablet(): boolean {
    return this.isTabletSubject.value;
  }

  get isDesktop$(): Observable<boolean> {
    return this.isDesktopSubject.asObservable();
  }

  get isDesktop(): boolean {
    return this.isDesktopSubject.value;
  }

  get isNativeMobile(): boolean {
    return this.isIOS || this.isAndroid;
  }

  get isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  get platformSpecificPadding(): string {
    if (this.isIOS) return '20px';
    if (this.isAndroid) return '16px';
    return this.isMobile ? '16px' : '24px';
  }

  applyPlatformClasses() {
    const classList = document.documentElement.classList;
    classList.add(`platform-${this._platform}`);
    if (this.isNativeMobile) {
      classList.add('native-mobile');
    }
  }
}