import { Component, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../header/header.component';
import { MainNavigationComponent } from '../../main-navigation/main-navigation.component';
import { OverlayService } from '../../shared/services/overlay.service';
import { MainNavigationModule } from 'src/app/main-navigation/main-navigation.module';

@Component({
  selector: 'app-main-layout',
  template: `
    <div class="app-layout">
      <div class="header-container">
        <app-header></app-header>
      </div>
      <div class="main-container">
        <app-main-navigation>
          <router-outlet></router-outlet>
        </app-main-navigation>
      </div>

      <div id="global-overlay" *ngIf="isOverlayVisible"
           class="global-overlay"
           [style.z-index]="overlayZIndex"
           (click)="overlayService.hide()">
        <div *ngIf="isActivityIndicatorVisible" class="loading-container">
          <div class="loading-content">
            <i class="fas fa-spinner fa-spin"></i>
            <div class="loading-text">Processing... Please Wait</div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .app-layout {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }

    .header-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 64px;
      z-index: 1050;
      background-color: #0a2c38;
      border-bottom: 1px solid #e5e7eb;
    }

    .main-container {
      flex: 1;
      margin-top: 64px;
      height: calc(100vh - 64px);
      overflow: hidden;
    }

    .global-overlay {
      position: fixed;
      inset: 0;
      background: rgba(0, 0, 0, 0.5);
      opacity: 1;
      transition: opacity 0.3s ease;
    }

    .loading-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loading-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .loading-content i {
      font-size: 64px;
      color: white;
    }

    .loading-text {
      color: white;
      font-size: 18px;
      font-weight: 500;
    }

    .fa-spin {
      animation: fa-spin 0.75s infinite linear;
    }

    @keyframes fa-spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    @media (max-width: 1366.98px) {
      .main-container {
        padding-bottom: 64px;
      }
    }
  `],
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, MainNavigationModule]
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
  isOverlayVisible: boolean = false;
  isActivityIndicatorVisible: boolean = false;
  overlayZIndex: number = 1000;

  constructor(
    public overlayService: OverlayService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Subscribe to overlay visibility
    this.overlayService.isVisible().subscribe(visible => {
      this.isOverlayVisible = visible;
      this.cdr.detectChanges();
    });

    // Subscribe to activity indicator
    this.overlayService.showingActivityIndicator().subscribe(showing => {
      this.isActivityIndicatorVisible = showing;
      this.cdr.detectChanges();
    });

    // Subscribe to z-index changes
    this.overlayService.getZIndex().subscribe(zIndex => {
      this.overlayZIndex = zIndex;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit() {
    // Ensure initial state is properly reflected
    this.cdr.detectChanges();
  }
}