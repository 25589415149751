import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Alert, AlertOptions, AlertType } from '../login/models/alert';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzMessageRef } from 'ng-zorro-antd/message';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { LayoutService } from '../services/layout.service';

interface MessageInfo {
    type: string;
    content: string;
    ref: NzMessageRef;
    isOpen: boolean;
}



@Injectable({ providedIn: 'root' })
export class AlertService implements OnDestroy {
    private bottomNavHeight = 0;
    private destroy$ = new Subject<void>();
    options = {
        autoClose: true,
        keepAfterRouteChange: true,
    };
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';
    private activeMessages: MessageInfo[] = [];

    constructor(
        public dialog: MatDialog,
        private message: NzMessageService,
        private nzConfigService: NzConfigService,
        private layoutService: LayoutService
    ) {
        // Subscribe to bottom nav height changes
        this.layoutService.getBottomNavHeight()
            .pipe(takeUntil(this.destroy$))
            .subscribe(height => {
                this.bottomNavHeight = height;
                // Update message position based on bottom nav height
                this.nzConfigService.set('message', {
                    nzDuration: 3000,
                    nzMaxStack: 3,
                    nzPauseOnHover: true,
                    nzAnimate: true,
                    nzTop: `calc(100vh - ${this.bottomNavHeight}px)`
                });
            });
    }

    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
    }

    private toTitleCase(str: string): string {
        return str.replace(/\w\S*/g, (txt) => {
            // Don't capitalize certain words like 'and', 'or', 'the', etc.
            const lowercaseWords = [];
            return lowercaseWords.includes(txt.toLowerCase()) ? txt.toLowerCase() : txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    private normalizeMessage(message: any): string {
        if (message === null || message === undefined) {
            return '';
        }

        let normalizedMessage = '';

        if (typeof message === 'string') {
            normalizedMessage = message.trim();
        } else if (message instanceof Error) {
            normalizedMessage = message.message.trim();
        } else if (typeof message === 'object' && 'message' in message) {
            const objMessage = message.message;
            if (typeof objMessage === 'string') {
                normalizedMessage = objMessage.trim();
            }
        } else {
            try {
                normalizedMessage = String(message).trim();
            } catch {
                return '';
            }
        }

        // Convert to Title Case
        return this.toTitleCase(normalizedMessage);
    }

    private preventDuplicate(type: string, content: any): boolean {
        const normalizedContent = this.normalizeMessage(content);
        const messageKey = `${type}_${normalizedContent}`;

        // Clean up closed messages first
        this.activeMessages = this.activeMessages.filter(m => m.isOpen);

        // Check for duplicates
        const existingMessage = this.activeMessages.find(m => {
            const existingKey = `${m.type}_${this.normalizeMessage(m.content)}`;
            return existingKey === messageKey;
        });

        if (existingMessage) {
            return true; // Duplicate found, prevent showing
        }

        return false; // No duplicate found, allow showing
    }

    // convenience methods
    success(message: string | Error | any, options: AlertOptions = this.options) {
        if (this.preventDuplicate('success', message)) return;

        const displayMessage = this.normalizeMessage(message);
        if (!displayMessage) return; // Don't show empty messages

        const ref = this.message.success(displayMessage, {
            nzDuration: 3000,
            nzPauseOnHover: true,
            nzAnimate: true
        });

        const messageInfo: MessageInfo = {
            type: 'success',
            content: displayMessage,
            ref: ref,
            isOpen: true
        };

        this.activeMessages.push(messageInfo);
        ref.onClose!.subscribe(() => {
            messageInfo.isOpen = false;
            this.cleanupMessages();
        });
    }

    error(message: string | Error | any, options: AlertOptions = this.options) {
        if (this.preventDuplicate('error', message)) return;
        if (message == 'isTrusted' || message?.includes('isTrusted')) {
            return;
        }

        // Check if we're on login-related pages
        const currentUrl = window.location.pathname.toLowerCase();
        const isLoginRelatedPage = currentUrl.includes('/login') ||
                                 currentUrl.includes('/forgot-password') ||
                                 currentUrl.includes('/reset-password');

        // Check if error is from activity service or api service
        const isActivityOrApiError =
            (typeof message === 'string' && (
                message.includes('Activity') ||
                message.includes('api.service')
            )) ||
            (message?.error?.url && (
                message.error.url.includes('/activity') ||
                message.error.url.includes('/api')
            ));

        // Skip showing error if we're on login pages and it's an activity/api error
        if (isLoginRelatedPage && isActivityOrApiError) {
            console.warn('Suppressed activity/api error on login page:', message);
            return;
        }

        const displayMessage = this.normalizeMessage(message);
        if (!displayMessage) return; // Don't show empty messages

        const ref = this.message.error(displayMessage, {
            nzDuration: options.nzDuration || 3000,
            nzPauseOnHover: options.nzPauseOnHover ?? true,
            nzAnimate: options.nzAnimate ?? true
        });

        const messageInfo: MessageInfo = {
            type: 'error',
            content: displayMessage,
            ref: ref,
            isOpen: true
        };

        this.activeMessages.push(messageInfo);
        ref.onClose!.subscribe(() => {
            messageInfo.isOpen = false;
            this.cleanupMessages();
        });
    }

    info(message: string | Error | any, options: AlertOptions = this.options) {
        if (this.preventDuplicate('info', message)) return;

        const displayMessage = this.normalizeMessage(message);
        if (!displayMessage) return; // Don't show empty messages

        const ref = this.message.info(displayMessage, {
            nzDuration: 3000,
            nzPauseOnHover: true,
            nzAnimate: true
        });

        const messageInfo: MessageInfo = {
            type: 'info',
            content: displayMessage,
            ref: ref,
            isOpen: true
        };

        this.activeMessages.push(messageInfo);
        ref.onClose!.subscribe(() => {
            messageInfo.isOpen = false;
            this.cleanupMessages();
        });
    }

    warn(message: string | Error | any, options: AlertOptions = this.options) {
        if (this.preventDuplicate('warning', message)) return;

        const displayMessage = this.normalizeMessage(message);
        if (!displayMessage) return; // Don't show empty messages

        const ref = this.message.warning(displayMessage, {
            nzDuration: 3000,
            nzPauseOnHover: true,
            nzAnimate: true
        });

        const messageInfo: MessageInfo = {
            type: 'warning',
            content: displayMessage,
            ref: ref,
            isOpen: true
        };

        this.activeMessages.push(messageInfo);
        ref.onClose!.subscribe(() => {
            messageInfo.isOpen = false;
            this.cleanupMessages();
        });
    }

    // main alert method
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
        this.message.remove();
        this.activeMessages = [];
    }

    private cleanupMessages(): void {
        // Remove closed messages from the array
        this.activeMessages = this.activeMessages.filter(m => m.isOpen);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
