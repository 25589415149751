import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomFormValidatorService {
    passwordPatternValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control?.value) {
                return null;
            }
            const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
            const validPattern = regex.test(control.value);
            return validPattern ? null : { invalidPassword: true };
        };
    }

    matchPasswordValidator(password: string, confirmPassword: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const passwordControl = formGroup?.get(password);
            const confirmPasswordControl = formGroup?.get(confirmPassword);

            if (!passwordControl || !confirmPasswordControl) {
                return null;
            }

            if (confirmPasswordControl?.errors && !confirmPasswordControl?.errors['passwordMismatch']) {
                return null;
            }

            if (passwordControl?.value !== confirmPasswordControl?.value) {
                confirmPasswordControl?.setErrors({ passwordMismatch: true });
                return { passwordMismatch: true };
            } else {
                confirmPasswordControl?.setErrors(null);
                return null;
            }
        };
    }
}
