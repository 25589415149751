import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './login/service/authguard';
import { SelectivePreloadingStrategy } from './common/selective-preloading.strategy';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ReportDashboardComponent } from './reports/report-dashboard.component';
import { TemplateMigrationTabComponent } from './reports/components/template-migration-tab/template-migration-tab.component';

const accountModule = () => import('./login/account.module').then((x) => x.AccountModule);
const clientModule = () => import('./client/module/client.module').then((x) => x.ClientModule);
const reportModule = () => import('./reports/module/report.module').then((x) => x.ReportModule);
const taskModule = () => import('./tasks/module/tasks.module').then((x) => x.TaskModule);
const staffModule = () => import('./staff/module/staff.module').then((x) => x.StaffModule);
const calendarModule = () => import('./calendar/module/calendar.module').then((x) => x.CalendarModule);
const billingModule = () => import('./billing/module/billing.module').then((x) => x.BillingModule);
const appointmentModule = () => import('./appointment/module/appointment.module').then((x) => x.AppointmentModule);
const migrationModule = () => import('./migration-dashboard/migration-dashboard.routes').then((x) => ({ default: x.MIGRATION_ROUTES }));


export const routes: Routes = [
  // Auth routes
{

  path: '',
  component: AuthLayoutComponent,
  children: [
      { path: '', redirectTo: 'account/login', pathMatch: 'full' },
      {
          path: 'account',
          children: [
              { path: 'login', component: LoginComponent },
              { path: 'forgot-password', component: ForgotPasswordComponent },
              { path: 'reset-password', component: ResetPasswordComponent }
          ]
      }
  ]
},
// Protected routes
{

    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./dashboard/components/dashboard.component').then(m => m.DashboardComponent),
         canActivate: [AuthGuard],
        data: {
          role: ['ADMIN', 'USER', 'STAFF'],
          breadcrumb: 'Home'
        }
      },
      {
        path: 'activity',
        loadChildren: () => import('./activity/activity.routes').then(m => ({ default: m.ACTIVITY_ROUTES })),
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN'],
          breadcrumb: 'Activity'
        }
      },
      {
        path: 'archive',
        loadComponent: () => import('./activity/activity-archive.component').then(m => m.ActivityArchiveComponent),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
            breadcrumb: 'Activity Archive'
        }
      },
      {
        path: 'reporting',
        loadChildren: () =>

          import('./reports/module/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard],
        data: { role: ['ADMIN', 'STAFF'], breadcrumb: 'Reports' }
      },
      {
        path: 'tasks',
        loadChildren: taskModule,
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN', 'STAFF'],
          breadcrumb: 'Tasks'
        }
      },
      {
        path: 'appointments',
        loadChildren: appointmentModule,
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN', 'STAFF'],
          breadcrumb: 'Appointments'
        }
      },
      {
        path: 'calendar',
        loadChildren: calendarModule,
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN', 'STAFF'],
          breadcrumb: 'Calendar'
        }
      },
      {
        path: 'billing',
        loadChildren: billingModule,
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN', 'STAFF'],
          preload: true,
          breadcrumb: 'Billing'
        }
      },
      {
        path: 'clients',
        loadChildren: clientModule,
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN', 'STAFF'],
          preload: true,
          breadcrumb: 'Clients'
        }
      },
      {
        path: 'staff',
        loadChildren: staffModule,
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN'],
          breadcrumb: 'Staff'
        }
      },
      {
        path: 'settings',
        loadComponent: () => import('./settings/settings.component').then(m => m.SettingsComponent),
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN', 'STAFF'],
          breadcrumb: 'Settings'
        }
      },

      {
        path: 'firebasemigration',
        loadChildren: migrationModule,
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN'],
          preload: true,
          breadcrumb: 'Firebase Migration'
        }
      },
      {
        path: 'simple-migration',
        loadComponent: () => import('./reports/simple-migration-tab.component').then(m => m.SimpleMigrationTabComponent),
        canActivate: [AuthGuard],

        data: {
          role: ['ADMIN'],
          breadcrumb: 'Simple Migration'
        }
      },
      {
        path: 'migration-dashboard',
        loadComponent: () => import('./migration-dashboard/migration-dashboard.component').then(m => m.MigrationDashboardComponent),
        canActivate: [AuthGuard],
        data: {
          role: ['ADMIN'],
          breadcrumb: 'Migration Dashboard'
        }
      },
      {
        path: 'template-migration',
        loadComponent: () => import('./reports/components/template-migration-tab/template-migration-tab.component').then(m => m.TemplateMigrationTabComponent),
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
            breadcrumb: 'Template Migration'
        }

    },
    {
        path: 'report-dashboard',
        loadComponent: () => import('./reports/report-dashboard.component').then(m => m.ReportDashboardComponent),
        canActivate: [AuthGuard],

        data: {

            role: ['ADMIN'],
        }
    },
    {
      path: 'audit-log-viewer',
      loadComponent: () => import('./reports/components/audit-log-viewer/audit-log-viewer.component').then(m => m.AuditLogViewerComponent),
      canActivate: [AuthGuard],
      data: {
        role: ['ADMIN'],
      }
    }
      ]
  },


  // Fallback route
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectivePreloadingStrategy
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
