import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { StaffModel } from '../model/staff.model';
import { MessageService } from '../../../../src/app/common/message.service';
import { environment } from '../../../../src/environments/environment';
import { UtilityService } from 'src/app/common/utility';
import { AuthService } from 'src/app/login/service/auth.service';
import { DeleteImageRequest } from '../model/delete-image-request.model';
import { ImageUploadRequest } from '../model/image-upload-request.model';
import { ApiService } from 'src/app/core/services/api.service';
import { ActivityService } from 'src/app/services/activity.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

interface NotificationSettings {
    emailNotifications: boolean;
    appointmentAlerts: boolean;
    messageNotifications: boolean;
    systemUpdates: boolean;
    themePreference?: 'dark' | 'light';
}

@Injectable({
    providedIn: 'root',
})
export class StaffService extends ApiService {
    http: HttpClient;
    attachmentUrl: string = '/Attachment';
    staffUrl: string = '/Staff';
    staffSettingsUrl: string = '/StaffSettings';
    private currentStaffSubject = new BehaviorSubject<StaffModel | null>(null);
    private settingsSubject = new BehaviorSubject<NotificationSettings | null>(null);

    currentStaff$ = this.currentStaffSubject.asObservable();
    settings$ = this.settingsSubject.asObservable();

    constructor(
        httpClient: HttpClient,
        private messageService: MessageService,
        private utility: UtilityService,
        activityService: ActivityService,
        private authService: AuthService
    ) {
        super(httpClient, activityService);
        this.http = httpClient;
    }

    deleteAvatar(uri: string): Observable<boolean> {
        const url = `${this.attachmentUrl}/DeleteImage`;
        console.log('deleting avatar', uri);
        const request: DeleteImageRequest = { uri };
        return this.post<boolean>(url, request).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of(false);
            })
        );
    }

    getByFirebaseUid(firebaseUid: string): Observable<HttpResponse<StaffModel>> {
        const url = `${this.staffUrl}/by-firebase-uid/${firebaseUid}`;
        const params = new HttpParams();
        return this.get<StaffModel>(url).pipe(
            map(response => new HttpResponse<StaffModel>({ body: response, status: 200, statusText: 'OK' })),
            catchError((error) => {
                this.activityService.logError(error);
                return of(new HttpResponse<StaffModel>({ body: null, status: 500, statusText: 'Internal Server Error' }));
            })
        );
    }

    getAllStaff(): Observable<HttpResponse<StaffModel[]>> {
        const url = `${this.staffUrl}/GetAll`;
        const params = new HttpParams();

        return this.get<StaffModel[]>(url, params).pipe(
            map(response => new HttpResponse<StaffModel[]>({ body: response, status: 200, statusText: 'OK' })),
            catchError((error: HttpErrorResponse) => {
                this.activityService.logError(error);
                return of(new HttpResponse<StaffModel[]>({ body: [], status: 500, statusText: 'Internal Server Error' }));
            })
        );
    }

    uploadAvatarAttachment(files: File[], clientId: string, staffId: string): Observable<any> {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });

        const request: ImageUploadRequest = {
            clientId: clientId,
            staffId: staffId,
        };
        formData.append('request.clientId', request.clientId || '');
        formData.append('request.staffId', request.staffId || '');

        const url = `${this.attachmentUrl}/UploadImages`;
        return this.post<any>(url, formData).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of(null);
            })
        );
    }

    getAvatar(staffId: any): Observable<any> {
        const url = `${this.attachmentUrl}/GetReferralAttachment?id=${staffId}`;
        return this.post<FormData>(url, {}).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of(null);
            })
        );
    }

    addStaff(staff: StaffModel): Observable<StaffModel[]> {
        const url = `${this.staffUrl}/AddStaff`;
        return this.post<StaffModel[]>(url, staff).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of([]);
            })
        );
    }

    updateStaff(staff: StaffModel): Observable<StaffModel[]> {
        const url = `${this.staffUrl}/UpdateStaff`;
        return this.post<StaffModel[]>(url, staff).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of([]);
            })
        );
    }

    deactivateClient(staff: StaffModel): Observable<StaffModel[]> {
        const url = `${this.staffUrl}/DeactivateStaff?id=${staff.staffId}`;
        return this.delete<StaffModel[]>(url).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of([]);
            })
        );
    }

    getSettings(): Observable<NotificationSettings | null> {
        const staffId = this.authService.getStaffId();
        const url = `${this.staffSettingsUrl}/current/${staffId}`;
        return this.get<NotificationSettings>(url).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of(null);
            })
        );
    }

    updateTheme(theme: string): Observable<NotificationSettings | null> {
        const staffId = this.authService.getStaffId();
        const url = `${this.staffSettingsUrl}/${staffId}/theme`;
        return this.put<NotificationSettings>(url, { theme }).pipe(
            tap((settings) => this.settingsSubject.next(settings)),
            catchError((error) => {
                this.activityService.logError(error);
                return of(null);
            })
        );
    }

    updateNotificationSettings(settings: NotificationSettings): Observable<NotificationSettings | null> {
        const staffId = this.authService.getStaffId();
        const url = `${this.staffSettingsUrl}/${staffId}/notifications`;
        return this.put<NotificationSettings>(url, settings).pipe(
            tap((updatedSettings) => this.settingsSubject.next(updatedSettings)),
            catchError((error) => {
                this.activityService.logError(error);
                return of(null);
            })
        );
    }

    getStaffById(id: string): Observable<HttpResponse<StaffModel>> {
        const url = `${this.staffUrl}/GetStaff?id=${id}`;
        const params = new HttpParams();

        return this.get<StaffModel>(url, params).pipe(
            map(response => new HttpResponse<StaffModel>({ body: response, status: 200, statusText: 'OK' })),
            catchError((error: HttpErrorResponse) => {
                this.activityService.logError(error);

                return of(new HttpResponse<StaffModel>({
                    body: null,
                    status: error.status || 500,
                    statusText: error.statusText || 'Internal Server Error'
                }));
            })
        );
    }

    resetStaffClients(id: string): Observable<StaffModel> {
        const url = `${this.staffUrl}/ResetStaffClients?id=${id}`;
        return this.get<StaffModel>(url).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                // Return a default StaffModel object to maintain type safety
                return of({
                    staffId: '',
                    staffNumber: '',
                    createdDate: new Date().toISOString(),
                    clients: [],
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    mobilePhone: '',
                    officePhone: '',
                    staffTitle: '',
                    titlesCertifications: '',
                    titlesName: '',
                    middleName: '',
                    dateofBirth: null,
                    hireDate: null,
                    staffColor: '',
                    profileImage: '',
                    isActive: 'false',
                    staffClientId: '',
                } as StaffModel);
            })
        );
    }

    updateMigrationStatus(staffId: string, status: string, firebaseUid?: string): Observable<boolean> {
        const url = `${this.staffUrl}/migration-status`;
        return this.post<boolean>(url, {
            staffId,
            status,
            firebaseUid,
        }).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of(false);
            })
        );
    }

    getStaffByMigrationStatus(status: string): Observable<StaffModel[]> {
        const url = `${this.staffUrl}/by-migration-status/${status}`;
        return this.get<StaffModel[]>(url).pipe(
            catchError((error) => {
                this.activityService.logError(error);
                return of([]);
            })
        );
    }

    private log(message: string) {
        this.messageService.add(`StaffService: ${message}`);
    }
}
