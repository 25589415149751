export class AuthenticatedResponse {
    id?: string;
    email?: string;
    accessToken?: string;
    refreshToken?: string;
    firstName?: string;
    lastName?: string;
    vendorName?: string;
    role?: string;
    staffId?: string;
    clientId?: string;
    staffNumber?: string;
    clientNumber?: string;
    profileImage?: string;
    lastLogin?: string;
    firebaseUid?: string;
    firebaseLastSync?: string;
    firebaseDeviceId?: string;
}

export class StringResponse {
    response: string;
}
