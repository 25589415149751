import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { User } from '../login/models/user';
import { AccountService } from '../login/service/account.service';
import { AuthService } from '../login/service/auth.service';
import { NavigationService } from '../services/navigation.service';
import { BehaviorSubject, Subject, Subscription, take, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { UserIdleConfig, UserIdleService } from 'angular-user-idle';
import { AlertService } from '../alerts/alert.service';
import { SpeedDialComponent, SpeedDialItemModel, SpeedDialModule } from '@syncfusion/ej2-angular-buttons';
import { FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { SidebarComponent, TabAllModule } from '@syncfusion/ej2-angular-navigations';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StaffModel } from '../staff/model/staff.model';
import { DeviceService } from '../services/device.service';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from '../zorro.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [CommonModule, RouterModule, NgZorroAntdModule, SpeedDialModule, TabAllModule, NgbDropdownModule]
})
export class HeaderComponent implements OnInit, OnDestroy {
    get vendorValue() {
        return this.staff;
    }
    set vendorValue(staff) {
        this.staff = staff;
    }

    @ViewChild('popupTempSpeeddial')
    public speeddialObj: SpeedDialComponent;
    private staff: string = 'Staff Member';
    newVendorCount: number;
    private _subscription: Subscription;
    isEnable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isEnable: boolean;
    isOpen: boolean = true;
    lastLogin = new Date();
    isLoading: boolean = false;
    linkText: boolean = this.isOpen;
    userLoggedIn: boolean = false;
    speedDialClicked: boolean = false;
    public role: string = '';
    private _vendorCountSubscription: Subscription;
    private _accountSubscription: Subscription;
    private _enableSubscription: Subscription;
    idelSessiondialogOpened: boolean = false;
    public miniNavDisplay: 'none' | 'flex';
    @ViewChild('formId', { static: true }) myGroup: FormGroup;
    @ViewChild('sidenav', { static: false }) sideNav: MatSidenav;
    config: UserIdleConfig;
    timeofDay: string = this.getGreeting();
    stateColor: string = '#13377d';
    defaultImage: any;
    isMobileMode: boolean = false;
    isMobile$: Observable<boolean>;
    private readonly MOBILE_BREAKPOINT = 1366.98;
    private destroy$ = new Subject<void>();
    currentStaff: StaffModel | null = null;

    constructor(
        public authService: AuthService,
        private router: Router,
        private alertService: AlertService,
        private userIdle: UserIdleService,
        private accountService: AccountService,
        private sanitizer: DomSanitizer,
        private navigationService: NavigationService,
        private breakpointObserver: BreakpointObserver,
        private cdr: ChangeDetectorRef,
        private deviceService: DeviceService,
        private modalService: NzModalService
    ) {
        this._accountSubscription = this.accountService.user.subscribe((count) => {
            this.role = count?.role?.toUpperCase();

            this.hasUserImage(count?.hasImage);
            this.vendorValue = count?.vendorName || `${count?.firstName} ${count?.lastName}`;
            if (typeof count?.accessToken !== 'undefined') {
                this.userLoggedIn = count?.accessToken !== '';
            }
        });

        // this._vendorCountSubscription = this.accountService.vendorCountObserver.subscribe((count) => {
        //     this.newVendorCount = count;
        // });

        this._enableSubscription = this.isEnable$.asObservable().subscribe((res) => (this.isEnable = res));

        // Subscribe to mobile state changes
        this.deviceService.isMobile$
            .pipe(takeUntil(this.destroy$))
            .subscribe(isMobile => {
                this.isMobileMode = isMobile;
            });
    }

    ngOnInit(): void {
        // Initialize mobile detection using breakpointObserver
        this.isMobile$ = this.breakpointObserver
            .observe([`(max-width: ${this.MOBILE_BREAKPOINT}px)`])
            .pipe(
                map(result => result.matches),
                takeUntil(this.destroy$)
            );

        // Update isMobileMode when breakpoint changes
        this.isMobile$.subscribe(isMobile => {
            this.isMobileMode = isMobile;
        });

        var img = new Image();
        img.src = 'assets/images/dummy-img.jpg';
        this.defaultImage = img;

        // Subscribe to avatar updates
        this.navigationService.getAvatarState()
            .pipe(takeUntil(this.destroy$))
            .subscribe(imageUrl => {
                if (imageUrl && this.currentStaff) {
                    this.currentStaff.profileImage = imageUrl;
                    this.cdr.detectChanges();
                }
            });

        // Subscribe to router navigation events
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                takeUntil(this.destroy$)
            )
            .subscribe((event: NavigationStart) => {
                if (this.checkPendingChanges()) {
                    const pdfViewer = document.querySelector('app-pdfViewer') as any;
                    if (pdfViewer?.componentInstance) {
                        this.modalService.confirm({

                            nzTitle: 'Save Changes?',
                            nzContent: 'Would you like to save your changes before leaving?',
                            nzCentered: true,
                            nzOkText: 'Save',
                            nzCancelText: 'Cancel',
                            nzOnOk: async () => {
                                await pdfViewer.componentInstance.SaveDocument();
                                this.router.navigate([event.url]);
                            },
                            nzOnCancel: () => {
                                this.router.navigate([event.url]);
                            }
                        });
                    }
                }
            });
    }

    ngOnDestroy(): void {
        console.log('Destroying all header subscriptions');
        this._vendorCountSubscription?.unsubscribe();
        this._accountSubscription?.unsubscribe();
        this._enableSubscription?.unsubscribe();
        this._subscription?.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    hasUserImage(value: boolean) {
        return value;
    }

    toggleSidenav() {
        this.navigationService.toggleSidenav();
    }

    checkForMobile() {
        if (window.innerWidth < 1366) {
            this.isMobileMode = true;
        }
    }
    goToRoute(route) {
        if (this.checkPendingChanges()) {
            // Get reference to the PDF viewer component
            const pdfViewer = document.querySelector('app-pdfViewer') as any;
            if (pdfViewer?.componentInstance) {
                // Show the save changes dialog
                this.modalService.confirm({
                    nzTitle: 'Save Changes?',
                    nzContent: 'Would you like to save your changes before leaving?',
                    nzCentered: true,
                    nzOkText: 'Save',
                    nzCancelText: 'Cancel',
                    nzOnOk: async () => {
                        await pdfViewer.componentInstance.SaveDocument();
                        this.isLoading = true;
                        setTimeout(() => {
                            this.router.navigate([route.RouterLink]);
                        });
                    },
                    nzOnCancel: () => {
                        this.isLoading = true;
                        setTimeout(() => {
                            this.router.navigate([route.RouterLink]);
                        });
                    }
                });
            }
        } else {
            this.isLoading = true;
            setTimeout(() => {
                this.router.navigate([route.RouterLink]);
            });
        }
    }
    goToProfile() {
        this.router.navigate(['staff/staffdetails'], {
            queryParams: { staff_id: this.authService.getStaffId() },
        });
    }
    backdropStart() {
        //   this.backdropDisplay = 'block';
    }

    backdropDone() {
        //this.backdropDisplay = (this.linkText ? 'block' : 'none')
    }

    rolebasedOptions(item): boolean {
        if (item && item.title == 'Settings') {
            let v = this?.authService?.getRole() == 'ADMIN';
            return !v;
        } else {
            return true;
        }
    }

    get name() {
        return this.myGroup?.get('check');
    }
    get email() {
        return this.myGroup?.get('email');
    }
    get feedback() {
        return this.myGroup?.get('feedback');
    }

    public openSpeedDial() {
        this.speedDialClicked = true;
        this.speeddialObj.show();
        this.myGroup = new FormGroup({
            name: new FormControl('', [FormValidators.required]),
            email: new FormControl('', [FormValidators.email]),
            feedback: new FormControl('', [FormValidators.required]),
        });
        this._subscription = this.myGroup.statusChanges?.subscribe((result: any) => {
            let val = result === 'VALID' ? true : false;
            this.onFormValidation(val);
        });
    }

    public closeClick() {
        this.myGroup?.reset();
        this.myGroup = null;
        this._subscription.unsubscribe();
        this.speedDialClicked = false;
        this.speeddialObj.hide();
    }
    onFormValidation(validity: boolean) {
        switch (validity) {
            case true:
                this.isEnable$.next(true);
                break;
            case false:
                this.isEnable$.next(false);
                break;
        }
    }
    public submitClick() {
        if (!this.isEnable) {
            Object.values(this.myGroup.controls).forEach((control) => {
                console.log('Contril Invalid', control);
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({ onlySelf: true });
                }
            });
            let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
            invalidFields[1]?.focus();
        } else {
            this.myGroup?.reset();
            this.myGroup = null;
            this.alertService.success('Thank you! Your feedback has been submitted.');
            this.speeddialObj.hide();
        }
    }

    getGreeting() {
        var myDate = new Date();
        var hrs = myDate.getHours();
        var mins = myDate.getMinutes(); // changed date to myDate
        var greet;

        //   morning |  5:30-11:59
        // afternoon | 12:00-17:59
        //   evening | 18:00-05:29
        if (hrs >= 5 && ((hrs == 5 && mins >= 30) || (hrs > 5 && hrs < 12))) greet = 'Good Morning, ';
        else if (hrs >= 12 && hrs < 18) greet = 'Good Afternoon, ';
        else if ((hrs >= 18 && hrs < 24) || hrs > 0) greet = 'Good Evening, ';
        else greet = 'Welcome, ';
        return greet;
    }
    getImageUrl(): SafeUrl {
        return this.authService.getUserImage();
    }
    async LogOut() {
        await this.authService.logout();
    }

    private checkPendingChanges(): boolean {
        // Get reference to active PDF viewer component if it exists
        const pdfViewer = document.querySelector('app-pdfViewer') as any;
        if (pdfViewer?.componentInstance?.contentChanged) {
            return true;
        }
        return false;
    }
}
