export type ActivitySeverity = 'Critical' | 'High' | 'Medium' | 'Low' | 'Info';

export interface IActivity {
    activityId: string;
    userId: string;
    sessionId: string;
    action: ActivityAction;
    timestamp: Date;
    metadata: ActivityMetadata;
    pageName?: string;
    severity?: ActivitySeverity;
}

export interface ActivityMetadata {
    page?: string;
    browser?: BrowserInfo;
    ipAddress?: string;
    routeParams?: { [key: string]: string };
    queryParams?: { [key: string]: string };
    reportId?: string;
    clientId?: string;
    documentId?: string;
    errorDetails?: ErrorDetails;
    performanceMetrics?: PerformanceMetrics;
    timeSpentSeconds?: number;
    lastPageVisitTime?: Date;
    email?: string;
    formName?: string;
    success?: boolean;
    searchTerm?: string;
    resultsCount?: number;
    format?: string;
    action?: string;
    versionNumber?: number;
    draftNumber?: number;
    reason?: string;
    location?: {
        city?: string;
        region?: string;
        country?: string;
        coordinates?: string;
        isp?: string;
    };
}

export interface BrowserInfo {
    name: string;
    version: string;
    os: string;
    device: string;
    screenResolution: string;
}

export interface ErrorDetails {
    message: string;
    stack?: string;
    status?: number;
    statusText?: string;
    url?: string;
    method?: string;
}

export interface PerformanceMetrics {
    loadTime?: number;
    responseTime?: number;
    processingTime?: number;
    renderTime?: number;
    totalTime?: number;
}

export enum ActivityAction {
    Login = 'Login',
    Logout = 'Logout',
    FailedLogin = 'FailedLogin',
    Error = 'Error',
    FormSubmission = 'FormSubmission',
    Download = 'Download',
    Upload = 'Upload',
    Search = 'Search',
    PageView = 'PageView',
    PageExit = 'PageExit',
    ReportCreate = 'ReportCreate',
    ReportEdit = 'ReportEdit',
    ReportDelete = 'ReportDelete',
    ReportView = 'ReportView',
    ReportExport = 'ReportExport',
    ReportLock = 'ReportLock',
    ReportUnlock = 'ReportUnlock',
    ReportLockOverride = 'ReportLockOverride',
    ReportDraftCreate = 'ReportDraftCreate',
    ReportDraftDelete = 'ReportDraftDelete',
    ReportDraftExpired = 'ReportDraftExpired',
    ReportVersionCreate = 'ReportVersionCreate',
    ReportVersionRestore = 'ReportVersionRestore',
    ReportDraftCheck = 'ReportDraftCheck',
    ReportDraftSave = 'ReportDraftSave',
    ReportDraftSaveCancel = 'ReportDraftSaveCancel',
    ReportDraftSaveContent = 'ReportDraftSaveContent',
    ReportDraftPreview = 'ReportDraftPreview',
    AIPrompt = 'AIPrompt',
    AIResponse = 'AIResponse',
    PasswordReset = 'PasswordReset',
    PermanentDelete = "PermanentDelete",
    ReportDeletionSyncError = "ReportDeletionSyncError",
}

export class ActivityModel implements IActivity {
    activityId: string = '';
    userId: string = '';
    sessionId: string = '';
    action: ActivityAction;
    timestamp: Date = new Date();
    metadata: ActivityMetadata = {};
    pageName?: string;

    constructor(init?: Partial<ActivityModel>) {
        Object.assign(this, init);
    }
}