import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { AccountService } from '../service/account.service';
import { AlertService } from '../../alerts/alert.service';
import { AuthService } from '../service/auth.service';
import { LoginService } from '../service/login.service';
import { CustomFormValidatorService } from '../service/custom.formvalidator.service';
import { FirebaseAuthService } from '../service/firebase-auth.service';
var passwordValidator = require('password-validator');

declare function hideHeader(): any;

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    standalone: false,
})
export class ResetPasswordComponent implements OnInit {
    form!: FormGroup;
    loading = false;
    submitted = false;
    showError: boolean = false;
    message: string;
    year: any = new Date().getFullYear();
    schema = new passwordValidator();
    hidePassword = true;
    hideConfirmPassword = true;
    private actionCode: string | null = null;

    constructor(
        private authService: AuthService,
        private router: Router,
        private loginService: LoginService,
        @Inject(FormBuilder) private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private alertService: AlertService,
        private readonly customFormValidator: CustomFormValidatorService,
        private firebaseAuth: FirebaseAuthService
    ) {
        // Get the action code (oobCode) from the URL
        const urlParams = new URLSearchParams(window.location.search);
        this.actionCode = urlParams.get('oobCode');

        // Verify the action code is valid
        if (this.actionCode) {
            this.firebaseAuth.verifyPasswordResetCode(this.actionCode).subscribe({
                error: (error) => {
                    console.error('Invalid or expired action code', error);
                    this.alertService.error(
                        'The password reset link has expired or is invalid. Please request a new one.'
                    );
                    this.router.navigate(['/account/forgot-password']);
                },
            });
        } else {
            //this.alertService.error('No reset code found. Please request a new password reset link.');
            this.router.navigate(['/account/forgot-password']);
        }
    }

    hide = true;
    ngOnInit(): void {
        hideHeader();
        this.resetForm();
        this.schema
            .is()
            .min(8) // Minimum length 8
            .is()
            .max(100) // Maximum length 100
            .has()
            .uppercase() // Must have uppercase letters
            .has()
            .lowercase() // Must have lowercase letters
            .has()
            .digits(2) // Must have at least 2 digits
            .has()
            .not()
            .spaces() // Should not have spaces
            .is()
            .not()
            .oneOf(['Passw0rd', 'Password123']); // Blacklist these values
    }

    get f() {
        return this.form.controls;
    }

    validateKeys(event) {
        console.log(this.schema.validate(this.passwordFormField.value, { details: true }));
    }

    onSubmit() {
        this.submitted = true;
        this.alertService.clear();

        if (this.form.invalid || !this.actionCode) {
            return;
        }

        this.loading = true;
        this.showError = false;
        const newPassword = this.f['password'].value;

        this.firebaseAuth
            .confirmPasswordReset(this.actionCode, newPassword)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success(
                        'Your password has been successfully reset. Please log in with your new password.'
                    );
                    this.loading = false;
                    this.router.navigate(['/account/login']);
                },
                error: (error: any) => {
                    this.loading = false;
                    this.submitted = false;

                    let errorMessage = 'Failed to reset password';
                    if (error.code === 'auth/expired-action-code') {
                        errorMessage = 'The password reset link has expired. Please request a new one.';
                    } else if (error.code === 'auth/invalid-action-code') {
                        errorMessage = 'Invalid reset link. Please request a new password reset.';
                    } else if (error.code === 'auth/weak-password') {
                        errorMessage = 'The password is too weak. Please choose a stronger password.';
                    }

                    this.alertService.error(errorMessage);
                    this.showError = true;
                },
            });
    }

    resetForm() {
        this.form = this.formBuilder.group(
            {
                password: ['', [Validators.required, this.customFormValidator.passwordPatternValidator()]],
                confirmPassword: ['', [Validators.required]],
            },
            {
                validators: [this.customFormValidator.matchPasswordValidator('password', 'confirmPassword')],
            }
        );
    }

    return() {
        this.router.navigate(['/account/login']);
    }

    get passwordFormField() {
        return this.form?.get('password');
    }

    get confirmPasswordFormField() {
        return this.form?.get('confirmPassword');
    }

    togglePasswordVisibility() {
        this.hidePassword = !this.hidePassword;
    }

    toggleConfirmPasswordVisibility() {
        this.hideConfirmPassword = !this.hideConfirmPassword;
    }
}
