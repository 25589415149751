import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  template: `
    <div class="auth-container">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      min-height: 100vh;
      min-width: 100vw;
      background-color: #f3f4f6;
    }

    .auth-container {
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      width: 100%;
    }
  `],
  standalone: true,
  imports: [RouterModule]
})
export class AuthLayoutComponent {}