import { Injectable } from '@angular/core';
import { PreloadAllModules, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelectivePreloadingStrategy implements PreloadAllModules {
    preloadedModules: string[] = [];

    preload(route: Route, load: () => Observable<any>): Observable<any> {
        const allowedPaths = ['reporting', 'billing', 'clients'];

        // Get the top-level path segment
        const segments = (route.path || '').split('/');
        const topLevelPath = segments[0] || '';

        // For reporting module, we need to handle the empty path case
        const effectivePath = topLevelPath === '' && route.data?.['breadcrumb'] === 'Reports'
            ? 'reporting'
            : topLevelPath;

        // Only preload if:
        // 1. The route has preload: true
        // 2. The path is in our allowed list
        // 3. We haven't already preloaded this path
        if (route.data?.['preload'] === true &&
            allowedPaths.includes(effectivePath) &&
            !this.preloadedModules.includes(effectivePath)) {

            this.preloadedModules.push(effectivePath);
            console.log('Preloading module:', effectivePath);
            return load();
        }

        return of(null);
    }
}
