import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Guid } from 'guid-typescript';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, catchError, map, of, tap, Subject, from, BehaviorSubject, switchMap, takeUntil, firstValueFrom, finalize } from 'rxjs';
import { OverlayService } from '../../shared/services/overlay.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticatedResponse } from '../models/authenticatedresponse';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { FirebaseAuthService } from './firebase-auth.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

interface AuthState {
    isAuthenticated: boolean;
    showHead: boolean;
    uid: string | null;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly AUTH_API =  environment.apiUrl + '/staff';
    private readonly SESSION_KEY = 'session_data';

    // Central state management
    private userSubject = new BehaviorSubject<User | null>(null);
    public user$ = this.userSubject.asObservable();

    // Event emitters
    private loginSuccessSubject = new Subject<void>();
    private logoutSubject = new Subject<void>();

    public loginSuccess$ = this.loginSuccessSubject.asObservable();
    public logout$ = this.logoutSubject.asObservable();

    http: HttpClient;
    private roleAs: string;
    private user: string;
    private staffId: string;
    private staffNumber: string;
    private userName: string;
    private userImage: any;
    private sessionID: string;
    private firebaseUid: string;

    private _authStateSubscription: any;
    private _destroy$ = new Subject<void>();

    // Add a new private property to keep track of when logout is in progress.
    private isLoggingOut: boolean = false;

    constructor(
        private httpClient: HttpClient,
        private overlayService: OverlayService,
        private sanitizer: DomSanitizer,
        public afAuth: AngularFireAuth,
        private router: Router,
        private firebaseAuthService: FirebaseAuthService,
        private ngZone: NgZone
    ) {
        console.log('🟡 [1] AuthService - Constructor initialized');
        this.http = httpClient;
        console.log('AuthService constructor');

        // Initialize from cached data first
        const sessionData = this.getSessionData();
        if (sessionData) {
            this.initializeFromSessionData(sessionData);
        }

        // Only initialize Firebase auth state if we're not on the login page
        if (!this.router.url.includes('/account/login')) {
            this.initializeAuthState();
        }
    }

    private initializeFromSessionData(sessionData: any): void {
        this.roleAs = sessionData.role || '';
        this.user = sessionData.user || '';
        this.staffId = sessionData.staffId || '';
        this.staffNumber = sessionData.staffNumber || '';
        this.userName = sessionData.userName || '';
        this.userImage = sessionData.userImage || '';
        this.sessionID = sessionData.sessionId || '';
        this.firebaseUid = sessionData.firebaseUid || '';

        const userData = this.getCachedUserData();
        if (userData) {
            console.log('🟡 Found cached user data, initializing state');
            this.userSubject.next(userData);

            // Only try to update Firebase auth state if we have a valid user
            if (userData.firebaseUid && userData.email) {
                console.log('🟡 Updating Firebase auth state with cached user');
                if (userData && userData.firebaseUid && userData.email) {
                    this.afAuth.updateCurrentUser({
                        uid: userData.firebaseUid,
                        email: userData.email,
                        emailVerified: false,
                        displayName: `${userData.firstName} ${userData.lastName}`,
                        photoURL: userData.profileImage,
                        providerData: []
                    } as any).catch(error => {
                        console.error('Failed to restore Firebase auth state:', error);
                    });
                } else {
                    console.error('Invalid userData object:', userData);
                    // Don't throw error, just log it - we'll handle auth state in other ways
                }
            }

            // Emit login success to trigger app component update
            this.loginSuccessSubject.next();

            // On refresh, stay on the current page
            const currentUrl = window.location.pathname;
            if (currentUrl === '/account/login') {
                // Only navigate to dashboard if coming from login page
                this.router.navigate(['/dashboard']);
            }
        }
    }

    private initializeAuthState(): void {
        console.log('🟡 [7] AuthService - Initializing auth state');
        if (this._authStateSubscription) {
            console.log('🟡 [8] AuthService - Unsubscribing from existing auth state');
            this._authStateSubscription.unsubscribe();
        }

        let lastKnownUser: any = null;
        let tokenRefreshInProgress = false;

        this._authStateSubscription = this.afAuth.authState.pipe(
            takeUntil(this._destroy$),
            switchMap(async (firebaseUser) => {
                console.log('🟡 [9] AuthService - Firebase auth state changed:',
                    firebaseUser ? 'User exists' : 'No user',
                    'Token refresh in progress:', tokenRefreshInProgress);

                // If we're refreshing token, maintain last known user
                if (!firebaseUser && tokenRefreshInProgress) {
                    console.log('🟡 Token refresh in progress, maintaining last known user');
                    return lastKnownUser;
                }

                if (!firebaseUser) {
                    const cachedUser = this.getCachedUserData();
                    if (cachedUser) {
                        console.log('🟡 No firebase user, using cached data', cachedUser);
                        // Update Firebase auth state with cached user
                        if (cachedUser.firebaseUid) {
                            console.log('🟡 Updating Firebase auth state with cached user in auth state subscription');
                            await this.afAuth.updateCurrentUser({
                                uid: cachedUser.firebaseUid,
                                email: cachedUser.email,
                                emailVerified: false,
                                displayName: `${cachedUser.firstName} ${cachedUser.lastName}`,
                                photoURL: cachedUser.profileImage,
                                providerData: []
                            } as any);
                            // Emit login success to ensure app component is updated
                            this.loginSuccessSubject.next();
                        }
                        return cachedUser;
                    }
                    return null;
                }

                try {
                    // Update last known user
                    lastKnownUser = this.getCachedUserData();

                    // Check if this is a token refresh
                    if (lastKnownUser?.firebaseUid === firebaseUser.uid) {
                        tokenRefreshInProgress = true;
                        console.log('🟡 Token refresh detected for same user');
                        const token = await firebaseUser.getIdToken();
                        tokenRefreshInProgress = false;
                        return lastKnownUser;
                    }

                    // Normal auth flow
                    const token = await firebaseUser.getIdToken();
                    const deviceId = await this.getDeviceId();
                    const response = await firstValueFrom(
                        this.http.post<AuthenticatedResponse>(`${this.AUTH_API}/update-login`, {
                            firebaseUid: firebaseUser.uid,
                            accessToken: token,
                            firebaseLastSync: new Date().toISOString(),
                            lastLogin: new Date().toISOString(),
                            //TODO: Add device id from mobile device
                            firebaseDeviceId: deviceId
                        })
                    );

                    const user = this.mapResponseToUser(response, firebaseUser.uid);
                    this.cacheUserData(user);
                    return user;
                } catch (error) {
                    console.error('🔴 Error in auth state management:', error);
                    this.handleInvalidSession();
                    if (lastKnownUser) {
                        console.log('🟡 Error occurred, maintaining last known user');
                        return lastKnownUser;
                    }
                    return null;
                }
            })
        ).subscribe(user => {
            if (!user && this.userSubject.value && tokenRefreshInProgress) {
                console.log('🟡 Maintaining existing user state during token refresh');
                return;
            }
            this.userSubject.next(user);
        });
    }

    private getDeviceId(): string {
        //TODO: Add device id from mobile device
        return '';
    }

    private getCachedUserData(): User | null {
        const data = localStorage.getItem('user_data');
        console.log('🟡 getCachedUserData', data);
        if (!data) return null;

        try {
            const userData = JSON.parse(data);
            console.log('🟡 Parsed cached user data:', userData);
            return userData;
        } catch (error) {
            console.error('🔴 Error parsing cached user data:', error);
            return null;
        }
    }

    private cacheUserData(user: User): void {
        localStorage.setItem('user_data', JSON.stringify(user));
    }

    private clearUserData(): void {
        localStorage.removeItem('user_data');
        // Clear other storage items...
    }

    // Core Authentication Methods
    login(email: string, password: string, useSqlAuth: boolean = false): Observable<User> {
        console.log('🟡 [2] AuthService - Login method called');
        return this.firebaseAuthService.loginWithFirebase(email, password).pipe(
            tap(user => {
                console.log('🟡 [3] AuthService - Firebase login successful, storing user data');
                this.storeUserData(user);
                console.log('🟡 [4] AuthService - User data stored');
                this.userSubject.next(user);
                console.log('🟡 [5] AuthService - User subject updated');
                this.loginSuccessSubject.next();
                console.log('🟡 [6] AuthService - Login success event emitted');
                // Initialize auth state only after successful login
                this.initializeAuthState();

                // After fresh login, navigate to dashboard
                this.router.navigate(['/dashboard']);
            })
        );
    }
    logout(): Observable<void> {
        console.log('🟡 AuthService 1 - logout() called');
        this.isLoggingOut = true;
        this.overlayService.showLoading('Logging Out...');

        // Create a subject to properly sequence our operations
        const logoutSequence = new Subject<void>();

        // Step 1: Unsubscribe from auth state
        if (this._authStateSubscription) {
            console.log('🟡 AuthService 2 - Unsubscribing from auth state subscription');
            this._authStateSubscription.unsubscribe();
            this._authStateSubscription = null;
        }

        // Step 2: Clear persisted data
        console.log('🟡 AuthService 3 - Clearing all persisted session data');
        localStorage.clear();
        sessionStorage.clear();
        indexedDB.deleteDatabase('firebaseLocalStorageDb');

        // Create a proper chain of Firebase operations
        const firebaseCleanup = async () => {
            try {
                console.log('🟡 AuthService 4 - Signing out from Firebase');
                await this.afAuth.signOut();

                console.log('🟡 AuthService 5 - Clearing current user');
                await this.afAuth.updateCurrentUser(null);

                console.log('🟡 AuthService 6 - Setting persistence to none');
                await this.afAuth.setPersistence('none');
                console.log('🟡 AuthService 7 - Persistence set to none');

                return true;
            } catch (error) {
                console.error('Firebase cleanup error:', error);
                return false;
            }
        };

        // Start the logout sequence
        from(firebaseCleanup()).pipe(
            catchError(error => {
                console.error('Firebase logout error:', error);
                return of(false);
            }),
            tap(() => {
                console.log('🟡 AuthService 8 - Performing Firebase auth service logout');
            }),
            switchMap(() => this.firebaseAuthService.logout().pipe(
                catchError(error => {
                    console.error('Firebase auth service logout error:', error);
                    return of(void 0);
                })
            )),
            tap(() => {
                console.log('🟡 AuthService 9 - Performing cleanup operations');
                this.userSubject.next(null);
                this.logoutSubject.next();
            }),
            finalize(() => {
                console.log('🟡 AuthService 10 - Finalizing logout');
                this.overlayService.hideLoading();

                console.log('🟡 AuthService 11 - Clearing all remaining data');
                this.clearAllData();

                console.log('🟡 AuthService 12 - Navigating to login page');
                this.ngZone.run(() => {
                    this.router.navigate(['/account/login']).then(() => {
                        console.log('🟡 AuthService 13 - Logout sequence completed');
                        this.isLoggingOut = false;
                    });
                });
            })
        ).subscribe(
            () => logoutSequence.next(),
            error => {
                console.error('Logout sequence error:', error);
                logoutSequence.error(error);
            },
            () => logoutSequence.complete()
        );

        return logoutSequence.asObservable();
    }

    // Session Management
    private async checkSession(): Promise<void> {
        console.log('🟡 AuthService - checkSession() called');
        try {
            console.log("Checking session");
            const sessionData = this.getSessionData();
            if (!sessionData) {
                this.handleInvalidSession();
                return;
            }

            const userData = this.getCachedUserData();
            if (!userData || !userData.firebaseUid || !userData.email) {
                this.handleInvalidSession();
                return;
            }

            // Update Firebase auth state with cached user data
            await this.afAuth.updateCurrentUser({
                uid: userData.firebaseUid,
                email: userData.email,
                emailVerified: false,
                displayName: `${userData.firstName} ${userData.lastName}`,
                photoURL: userData.profileImage,
                providerData: []
            } as any);

            // Get fresh ID token after updating auth state
            const user = await this.afAuth.currentUser;
            if (!user) {
                this.handleInvalidSession();
                return;
            }

            const token = await user.getIdToken();
            const response = await this.http.post<AuthenticatedResponse>(`${this.AUTH_API}/validate-session`, {
                    firebaseUid: userData.firebaseUid,
                    accessToken: token
                })
                .toPromise();

            if (response) {
                const validUser = this.mapResponseToUser(response, userData.firebaseUid);
                this.userSubject.next(validUser);
                this.loginSuccessSubject.next();
            } else {
                this.handleInvalidSession();
            }
        } catch (error) {
            console.error('Session validation error:', error);
            this.handleInvalidSession();
        }
    }

    // Password Management
    resetPasswordWithFirebase(email: string): Observable<void> {
        return from(this.afAuth.sendPasswordResetEmail(email, { url: 'https://myworkatcornerstone.com/account/reset-password' }));
    }

    updatePassword(newPassword: string): Observable<void> {
        return from(this.afAuth.currentUser).pipe(
            switchMap(user => {
                if (!user) throw new Error('No authenticated user');
                return from(user.updatePassword(newPassword));
            })
        );
    }

    // State Management
    public handleInvalidSession(): void {
        console.log('🟡 AuthService - handleInvalidSession() called');
        console.log('🟡 Invalid session stack trace:', new Error().stack);
        console.log("handleInvalidSession");

        // Clear data regardless of logout state
        this.clearAllData();

        // Force navigation to login even if logout is in progress
        const isMobileTab = window.location.href.includes('/tab/');
        const navigationPath = isMobileTab ? '/tab/account/login' : '/account/login';

        // Force navigation inside Angular's zone
        this.ngZone.run(() => {
            this.router.navigateByUrl(navigationPath, { replaceUrl: true })
                .catch(error => {
                    console.error('Navigation error in handleInvalidSession:', error);
                    // Fallback to direct window navigation
                    window.location.href = navigationPath;
                });
        });
    }

    private clearAllData(): void {
        console.log("clearAllData");
        this.userSubject.next(null);
        localStorage.clear();
        this.logoutSubject.next();
    }

    // Getters
    public get currentUser(): User | null {
        return this.userSubject.value;
    }

    isLoggedIn(): Observable<boolean> {
        return this.user$.pipe(map(user => !!user));
    }

    getUserImage() {
        const imageUrl =
            localStorage.getItem('USERIMAGE') ||
            'https://myworkatcornerstone.blob.core.windows.net/imagescontainer/default_avatar.jpg';
        return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
    }
    getDefaultProfileImage() {
        return 'https://myworkatcornerstone.blob.core.windows.net/imagescontainer/default_avatar.jpg';
    }
    getRole(): string {
        const sessionData = this.getSessionData();
        return sessionData?.role || '';
    }

    isAdmin(): boolean {
        return this.getRole() === 'ADMIN';
    }

    getUser() {
        return localStorage.getItem('USER') || '';
    }

    getStaffId() {
        return localStorage.getItem('STAFFID') || '';
    }

    getStaffNumber() {
        return localStorage.getItem('STAFFNUMBER') || '';
    }

    getUsersName() {
        return localStorage.getItem('USERNAME') || '';
    }

    setUserImage(imageUrl: string) {
        localStorage.setItem('USERIMAGE', imageUrl);
        this.userImage = imageUrl;
    }

    getSessionID() {
        return localStorage.getItem('SESSIONID') || '';
    }

    getFirebaseUid(): string {
        return localStorage.getItem('FIREBASEUID') || '';
    }

    sendPasswordResetEmail(email: string): Observable<void> {
        return from(this.afAuth.sendPasswordResetEmail(email)).pipe(
            tap({
                next: (result) => {
                    console.log('Password reset email sent:', result);
                },
                error: (error) => {
                    console.error('Firebase password reset error:', error);
                    throw error;
                },
                complete: () => {
                    console.log('Password reset request completed');
                }
            })
        );
    }


    private getSessionData(): any {
        const data = localStorage.getItem(this.SESSION_KEY);
        return data ? JSON.parse(data) : null;
    }

    // Helper Methods
    private mapResponseToUser(response: AuthenticatedResponse, firebaseUid: string = ''): User {
        const user = new User();
        user.id = response?.staffId || response?.clientId || '';
        user.email = response?.email || '';
        user.firstName = response?.firstName || '';
        user.lastName = response?.lastName || '';
        user.role = response?.role || '';
        user.accessToken = response?.accessToken || '';
        user.refreshToken = response?.refreshToken || '';
        user.lastLogin = response?.lastLogin || new Date().toISOString();
        user.firebaseUid = firebaseUid;
        user.vendorName = response?.vendorName;
        user.staffId = response?.staffId;
        user.staffNumber = response?.staffNumber;
        user.profileImage = response?.profileImage;
        return user;
    }

    public storeUserData(user: User): void {
        if (!user) return;

        console.log("storeUserData", user);
        const sessionData = {
            role: user.role?.toUpperCase(),
            user: JSON.stringify(user),
            staffId: user.staffId?.toString(),
            staffNumber: user.staffNumber,
            userName: user.vendorName || `${user.firstName} ${user.lastName}`,
            userImage: user.profileImage,
            sessionId: Guid.create().toString(),
            firebaseUid: user.firebaseUid
        };
        localStorage.setItem('user_data', JSON.stringify(user));
        localStorage.setItem(this.SESSION_KEY, JSON.stringify(sessionData));

        // Store individual items for backward compatibility
        Object.entries(sessionData).forEach(([key, value]) => {
            localStorage.setItem(key.toUpperCase(), value?.toString() || '');
        });
        console.log('storeUserData', localStorage.getItem('USERIMAGE'), this.getUserImage());
        console.log('staffId', localStorage.getItem('STAFFID'), this.getStaffId());
        // Update the user subject
        this.userSubject.next(user);
    }

    public getAuthState(): Observable<{ isAuthenticated: boolean }> {
        return this.user$.pipe(
            map(user => ({ isAuthenticated: !!user }))
        );
    }
}
